import React, { useState } from "react";
import { workData } from "./data";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Image from 'react-bootstrap/Image';


export const Works = () => {
  const initialShowState = Object.fromEntries(
    workData.map((data) => [data.projectTitle, false])
  );
  const [show, setShow] = React.useState(initialShowState);
  const toggleShow = (id) =>
    setShow((prev) => {
      return { ...prev, [id]: !prev[id] };
    });
  console.log({ show })
  return (
  <>
    <div className="work-container">
    <Row xs={1} md={2} lg={4} className="g-4">
        {workData.map((data, key) => {
              return (
                    <div key={key}>
                        <Col>
                            <Card className="bg-dark text-white">
                                <Card.Img variant="top" src={data.projectImage} />
                                <Card.Body>
                                    <Card.Title>{data.projectTitle}</Card.Title>
                                    <Card.Text>
                                    {data.projectTeam}
                                    <br></br>
                                    {data.year}
                                    </Card.Text>
                                    <Button variant="link" onClick={() => toggleShow(data.projectTitle)}>
                                    {data.readMore}
                                    </Button>
                                </Card.Body>
                                <Card.Footer>{data.tags}</Card.Footer>
                            </Card>
                        </Col>

                        <Modal
                        show={show[data.projectTitle]}
                        onHide={() => toggleShow(data.projectTitle)}
                        dialogClassName="modal-90w"
                        >
                            <Modal.Header closeButton>
                            <Modal.Title>{data.projectTitle}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Image src={data.projectImage} fluid />
                                <br></br><br></br>
                                <p>{data.modalText}</p>
                                <p>{data.skills}</p>
                                <Image src={data.modalImage} fluid />
                                <Image src={data.modalImage2} fluid />
                            </Modal.Body>
                            <Modal.Footer>{data.tags} <a href={data.link} >{data.linkname}</a> <a href={data.link2} >{data.linkname2}</a> </Modal.Footer>
                        </Modal>
                    </div>
              );
        })}
    </Row>
    </div>
    <br></br>
  </>
  );
}
